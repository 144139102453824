   .impressum-div {
    max-width: 80%;
    font-size: 16px;
    margin: 0 auto;
    padding: 1%;
    text-align: start;
  }
  
  .impressum-div ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  @media only screen and (max-width: 600px) {
    .impressum-div {
      max-width: 100%;
      padding: 0 20px;
    }
  } 