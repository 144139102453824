:root {
	--mainColor: #ffffff;
	--mainColorLight: #5767aa;
	--secondaryColor: #db2b39;
	--textColor: #000000;
}

header {
	display: flex;
	align-items: center;
    width: 100% !important;
	justify-content: space-between;
	height: 80px;
    z-index: 10;
	position: sticky;
	top: 0;
    overflow: hidden;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
	padding: 0 2rem;
	background-color: var(--mainColor);
	color: var(--textColor);
    font-weight: bolder;
}

nav a {
    font-size: 18px;
	margin: 0 1rem;
	color: black;
	text-decoration: none;
    transition: transform .1s ease-in-out;
}


nav a:hover {
    color: black;
    font-weight: bolder;
    border-bottom: 3px solid black;
    border-radius: 5px;
    transition: 0.10s;
}

header .nav-btn {
	padding: 5px;
	cursor: pointer;
	background: transparent;
	border: none;
	outline: none;
	color: var(--textColor);
	visibility: hidden;
	opacity: 0;
	font-size: 1.8rem;
}

header div,
nav {
	display: flex;
	align-items: center;
}

@media only screen and (max-width: 1024px) {
	header .nav-btn {
		visibility: visible;
		opacity: 1;
	}

	header nav {
		position: fixed;
		top: -100vh;
		left: 0;
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 1.5rem;
		background-color: var(--mainColor);
		transition: 1s;
		
	}

	header .responsive_nav {
		transform: translateY(100vh);
		
	}

	nav .nav-close-btn {
		position: absolute;
		top: 2rem;
		right: 2rem;
	}

	nav a {
		font-size: 1.5rem;
	}
}