.contact-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2rem auto;
    width: 100%;
  }
  
  .ant-form-item-label {
    display: flex;
    align-items: center;
  }
  
  .ant-form-item-label > label[for] {
    margin-bottom: 0;
    margin-right: 0.5rem;
  }
  
  .ant-form-item-control {
    margin-bottom: 1rem;
  }
  
  .ant-input-affix-wrapper {
    border-radius: 0.5rem;
  }
  .ant-input:hover, .ant-input:focus {
    border-color: #645124 !important;
  }

  .ant-input-affix-wrapper:focus-within {
    border-color: #645124;
  }
  
  .ant-input-affix-wrapper > input {
    border: none;
  }
  
  .ant-input-affix-wrapper > input:focus {
    outline: none;
  }
  
  .ant-input-affix-wrapper  input {
    padding: 5px !important;
  }

  .ant-input-affix-wrapper > input,
  .ant-input-affix-wrapper > textarea {
    border-bottom: 5px solid grey;
  }

  .contact-button {
    height: auto;
    color: white;
    border-radius: 6px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    background-color: #645124;
  }

  .contact-button:hover {
    height: auto;
    color: white;
    font-size: 16px;
    background-color: #645124 !important;
  }

  